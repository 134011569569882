<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'weekly'}" v-on:click="setTempPeriod('weekly')" ref="view_type_weekly" data-toggle="pill" data-period="weekly">{{ $t('period.weekly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>

            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-weekly-filter" v-on:submit.prevent="applyFilter()">

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in $util.years(2021)" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod == 'weekly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filWeek" id="week" name="week" ref="week" >
                          <option v-for="(week, index) in filterOptions.weeks" v-bind:key="index" :value="index" :selected="filters.filWeek === parseInt(index)" >{{ week }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.week') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod == 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in $util.months()" v-bind:key="index" :value="index" :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                  <!-- <div class="col-12">
                    <button type="button" v-on:click="onShow">Console</button>
                  </div> -->
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Navbar -->
      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.booking-control.status.stillavailable') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.booking-control.status.completed') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 5 }"
                     v-on:click="applyStatusFilter(3)" data-toggle="tab">{{ $t('form.booking-control.status.overbooking') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="getActiveFilterList" />
      </div>

      <!-- Main -->
      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12 px-0">
            <div class="card">
              <div class="card-body">
                <!--  Actions -->
                <div class="flexbox px-0 py-3 mb-3">
                  <div class="d-flex align-items-center">
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link v-show="isBookingAdmin || isSuperAdmin"
                      :to="{name: 'BookingContractNew'}" class="btn btn-sm btn-outline-light pull-right">
                      Nova Semana
                    </router-link>
                    <router-link v-show="isBookingRequester || isSuperAdmin"
                      :to="{name: 'BookingContractGroupIndex'}" class="btn btn-sm btn-outline-light pull-right">
                      Grupos
                    </router-link>
                    <router-link v-show="isBookingAdmin || isSuperAdmin"
                      :to="{name: 'BookingIndex'}" class="btn btn-sm btn-outline-light pull-right">
                      Equipamentos
                    </router-link>
                  </div>
                </div>
                <!--  End Actions -->
                <!-- Table -->
                <div class="table-responsive px-0">
                  <vuetable ref="vuetable"
                    :noDataTemplate="this.$i18n.t('empty')"
                    :api-url="endpoint"
                    :fields="vTableFields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path="pagination"
                    :per-page="20"
                    :multi-sort="multiSort"
                    :append-params="filter"
                    multi-sort-key="shift"
                    :http-options="httpOptions"
                    track-by="id_process"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded"
                    @vuetable:cell-clicked="onRowClicked">
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                  :css="css.pagination" @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
// import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// import dayjs from 'dayjs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

import BookingService from '@/services/BookingService'
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'

import Details from '@/views/Booking/Detail.vue'
import OptionsList from './../OptionsList.vue'
import ApplyForm from './../ApplyForm.vue'

export default {
  name: 'BookingList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.booking') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      endpoint: process.env.VUE_APP_APIENDPOINT + 'v2/booking/contract',
      multiSort: true,
      statusOptions: [
        {
          color: '#f1ba26',
          label: 'Disponível'
        },
        {
          color: '#5ec65f',
          label: 'Completo'
        },
        {
          color: '#ff0000',
          label: 'Overbooked'
        }
      ],
      employeeList: [],
      userList: [],
      customerList: [],
      activeFilterList: {
        begin_date: null,
        end_date: null
      },
      filter: {},
      filterOptions: {
        years: [],
        weeks: [],
        months: []
      },
      tempPeriod: 'yearly',
      defaultPeriod: 'yearly',
      fieldsTable: [
        {
          name: 'id',
          title: this.$i18n.t('form.booking-control.id'),
          sortField: 'id',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'contrato',
          title: this.$i18n.t('form.booking-control.contrato'),
          sortField: 'contrato',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'semana',
          title: this.$i18n.t('form.booking-control.semana'),
          sortField: 'semana',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'teus_contratados',
          title: this.$i18n.t('form.booking-control.teus_contratados'),
          sortField: 'teus_contratados',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'teus_utilizados',
          title: this.$i18n.t('form.booking-control.teus_utilizados'),
          sortField: 'teus_utilizados',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'etd',
          title: this.$i18n.t('form.booking-control.etd'),
          sortField: 'etd',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'deadLine',
          title: this.$i18n.t('form.booking-control.ddl'),
          sortField: 'deadLine',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'situacao',
          title: this.$i18n.t('form.booking-control.situacao'),
          sortField: 'situacao',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        }
      ],
      sortOrder: [
        { field: 'semana', direction: 'desc' },
        { field: 'situacao', direction: 'desc' },
        { field: 'contrato', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    FilterContainer,
    Loading
  },
  // Apply filters to local filter
  beforeMount () {
    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */
    // eslint-disable-next-line
    Date.prototype.getWeek = function (dowOffset) {
      // default dowOffset to zero
      dowOffset = dowOffset !== undefined && typeof (dowOffset) === 'number' ? dowOffset : 0
      let newYear = new Date(this.getFullYear(), 0, 1)
      // the day of week the year begins on
      let day = newYear.getDay() - dowOffset
      day = (day >= 0 ? day : day + 7)
      let daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
      let weeknum
      // if the year starts before the middle of a week
      if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1
        if (weeknum > 52) {
          let nYear = new Date(this.getFullYear() + 1, 0, 1)
          let nday = nYear.getDay() - dowOffset
          nday = nday >= 0 ? nday : nday + 7
          // if the next year starts before the middle of the week, it is week #1 of that year
          weeknum = nday < 4 ? 1 : 53
        }
      } else {
        weeknum = Math.floor((daynum + day - 1) / 7)
      }
      return weeknum
    }

    this.loadFilterData()

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    onShow () {
    },
    loadFilterData () {
      return new Promise((resolve, reject) => {
        BookingService.getContractFilterOptions().then(res => {
          this.filterOptions = res.data.data
        }).catch((err) => {
          reject(err)
        })
      })
    },
    buildFilters () {
      if (['monthly', 'weekly', 'yearly'].indexOf(this.filters.filPeriodo) === -1) {
        this.filters.filPeriodo = this.defaultPeriod
      }

      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      /** Na primeira abertura seta o filtros com o padrão
       *  semana, mês e ano atual */
      let today = new Date()
      if (!this.filters.filYear) {
        this.filters.filYear = today.getFullYear()
      }

      if ((!this.filters.filWeek)) {
        this.filters.filWeek = today.getWeek()
      }

      if (!this.filters.filMonth) {
        this.filters.filMonth = today.getMonth() + 1
      }

      this.filter.year = this.filters.filYear
      this.filter.week = this.filters.filWeek
      this.filter.month = this.filters.filMonth
      // this.filter.id_status = null

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      // Period
      if (this.$refs.view_type_weekly.classList.contains('active')) {
        this.filters.filPeriodo = 'weekly'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filWeek = this.$refs.week ? parseInt(this.$refs.week.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()
      this.$store.dispatch('setAppliedFilter', this.filters)
      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuickSidebar()

      let today = new Date()
      this.filters.filYear = today.getFullYear()
      this.filters.filWeek = today.getWeek()
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = this.defaultPeriod
      this.filter.id_status = null

      this.$store.dispatch('setAppliedFilter', this.filters)
      this.buildFilters()
      this.$refs.vuetable.refresh()
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    // getDescription (current, previous, label, prefix, valueFormatter) {
    //   if (current === undefined || isNaN(current)) {
    //     current = 0
    //   }

    //   if (previous === undefined || isNaN(previous)) {
    //     previous = 0
    //   }

    //   let lessThanInfo = 'na semana anterior.'
    //   let equalInfo = 'a semana anterior.'

    //   if (this.filter.period === 'yearly') {
    //     lessThanInfo = 'no ano anterior.'
    //     equalInfo = 'o ano anterior.'
    //   } else if (this.filter.period === 'monthly') {
    //     lessThanInfo = 'no mês anterior.'
    //     equalInfo = 'o mês anterior.'
    //   }

    //   let diferenca = current - previous
    //   prefix = prefix || ''
    //   if ((diferenca) > 0) {
    //     return prefix + ((valueFormatter) ? valueFormatter(diferenca) : this.showNumber(diferenca)) + ' ' + label + ' a mais que ' + lessThanInfo
    //   } else if ((diferenca) === 0) {
    //     return prefix + ' ' + label + ' iguais ' + equalInfo
    //   } else {
    //     return prefix + ((valueFormatter) ? valueFormatter(diferenca) : this.showNumber(diferenca * -1.0)) + ' ' + label + ' a menos que ' + lessThanInfo
    //   }
    // },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (!value || value === null || value === undefined || !this.statusOptions.length) {
        return ' - '
      }

      let status = this.statusOptions[value - 1]

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.$router.push({ name: 'BookingContractView', params: { id: row.data.id } })
    },
    /**
     * Display modal with Process details and files if process has files (active tab "Timeline")
     * @param data Process data from line selected
     */
    showDetails (data) {
      let me = this
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(Details)
        var detailsInstance = new ComponentClass({
          data: {
            user: me.user,
            booking: data,
            statusOptions: me.statusOptions
          }
        })
        detailsInstance.$mount()
        detailsInstance.$on('apply', this.onApply)
        detailsInstance.$on('leave', this.onLeave)
        let detailsProcessContent = detailsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsProcessContent, null,
          'no-padding', { showConfirmButton: false }).then((result) => { })
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getFuncionarios(filters).then(res => {
          _this.employeeList = res.data.data
          resolve(_this.employeeList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    userFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getUsuarios(filters).then(res => {
          _this.userList = res.data.data
          resolve(_this.userList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    onActionsClicked (row) {
      this.showOptions(row.rowData)
    },
    showOptions (data) {
      let me = this
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(OptionsList)
        var optionsInstance = new ComponentClass({
          data: {
            user: me.user,
            booking: data,
            status: this.statusColor(data.situacao)
          }
        })
        optionsInstance.$mount()
        optionsInstance.$on('delete', this.onDelete)
        optionsInstance.$on('update', this.onUpdateStatus)
        optionsInstance.$on('apply', this.onApply)
        optionsInstance.$on('leave', this.onLeave)
        let optionsProcessContent = optionsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, optionsProcessContent, null,
          'no-padding', { showConfirmButton: false }).then((result) => { })
      }
    },
    onDelete (id) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir este booking?',
        'warning', 'Sim', 'Não', (e) => this.deleteBooking(id)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro excluído com sucesso!')
        } else {
          this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
        }

        this.$refs.vuetable.refresh()
      })
    },
    async deleteBooking (id) {
      let _this = this
      _this.isLoading = true
      await BookingService.deleteBooking(id).then(res => {
        // if (res.httpCode === 200 && res.data.status === 'ok') {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onUpdateStatus (booking, newStatus) {
      let currentStatusLabel = this.statusOptions[booking.situacao]
      let newStatusLabel = this.statusOptions[newStatus]
      let message = `Confirma a alteração de situação de "${currentStatusLabel}" para "${newStatusLabel}"?`

      this.$alertSwal.modalConfirm('', message,
        'warning', 'Sim', 'Não', (e) => this.updateBookingStatus(booking.id, newStatus)).then((res) => {
        if (res.value.success) {
          this.$alertSwal.toast(res.value.message)
        } else {
          let message = `Não foi possível alterar o registro!<br/> ${res.value.message}`
          this.$alertSwal.toast(message, 'error')
        }
        this.$refs.vuetable.refresh()
      })
    },
    async updateBookingStatus (bookingId, newStatusKey) {
      let _this = this
      _this.isLoading = true

      let data = {
        id: bookingId,
        situacao: newStatusKey
      }

      let resp = await BookingService.updateBookingStatus(data).then(res => {
        _this.isLoading = false
        return {
          success: true,
          message: res.data.message,
          record: res.data.data
        }
      }).catch((e) => {
        _this.isLoading = false
        return {
          success: false,
          message: e.message
        }
      })

      return resp
    },
    onApply (id, user) {
      var ComponentClass = Vue.extend(ApplyForm)
      var applyInstance = new ComponentClass({
        data: {
          user: user,
          booking: id,
          option: 'applying'
        }
      })
      applyInstance.$mount()
      applyInstance.$on('refresh', this.refreshPage)
      let optionsProcessContent = applyInstance.$el
      global.instanceApp.$alertSwal.modalAlert(null, optionsProcessContent, null,
        'no-padding', { showConfirmButton: false }).then((result) => { })
    },
    refreshPage () {
      this.$refs.vuetable.refresh()
    },
    onLeave (id, user) {
      let data = {
        booking: id,
        user: user
      }
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja devolver este booking?',
        'warning', 'Sim', 'Não', (e) => this.leaveBooking(data)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro devolvido com sucesso!')
        } else {
          this.$alertSwal.toast('Não foi possível devolver o registro!', 'error')
        }

        this.$refs.vuetable.refresh()
      })
    },
    async leaveBooking (id) {
      let _this = this
      _this.isLoading = true
      await BookingService.leaveBooking(id).then(res => {
        // if (res.httpCode === 200 && res.data.status === 'ok') {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    },
    process () {
      return this.$route.params.process || null
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    },
    isTheSeller () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo) {
        return userInfo.active_employee.id_table_origin === this.booking.idVendedor
      }
      return false
    },
    isTheRequester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo) {
        return userInfo.active_employee.id_table_origin === this.booking.idSolicitante
      }
      return false
    },
    getActiveFilterList () {
      let activedFilterList = {
        period: null,
        employees: null,
        year: null
      }

      if (this.filters.filPeriodo === 'weekly') {
        activedFilterList = {
          period: null,
          employees: null,
          year: null,
          week: null
        }
      } else if (this.filters.filPeriodo === 'monthly') {
        activedFilterList = {
          period: null,
          employees: null,
          year: null,
          month: null
        }
      }

      return activedFilterList
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }

  th:hover{
    color: #A69073 !important
  }
</style>
